.prs-share-flow-view-down {
	.inside {
		&:not(:last-child) {
			span {
			  width: 0; 
			  height: 0; 
			  border-left: 15px solid transparent;
			  border-right: 15px solid transparent;
			  position: relative;
			  border-top: 15px solid #f00;
			}
		}
	}
}

.prs-share-flow-view-up {
	&:not(:last-child) {
		span {
		  width: 0; 
		  height: 0; 
		  border-left: 15px solid transparent;
		  border-right: 15px solid transparent;
		  position: relative;
		  border-top: 15px solid #f00;
		}
	}
}

#app {
	height: 100vh;
	max-height: 100vh;
	min-height: 100vh;
}
