$navbar-height: 63px;
///// SIDEBAR /////
$sidebar-width-icon: 70px;
///// SIDEBAR /////
///// ASIDE /////
$aside-main-width: 330px;
$aside-second-width: 300px;
$aside-main-share-width: 470px;
$aside-lamp-main-width: 370px;
$aside-second-width: 300px;
///// ASIDE /////
///// LIST-JOB /////
$list-width: 400px;
///// LIST-JOB /////
