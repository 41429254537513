.leaflet-div-icon-small {
  background: #fff;
  text-align:center;
}

/* css to customize Leaflet default styles  */
.custom .leaflet-popup-tip,
.custom .leaflet-popup-content-wrapper {
    background: #fbfbfb;
    color: #000000;
    border-radius: 0px;
    max-width: 400px;
}

.custom .leaflet-popup-content-wrapper {
  max-width: 400px !important;
  width: 350px !important;
  font-size: 20px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  text-align: left;

  .leaflet-popup-content {
  	max-width: 400px !important;
    width: 100% !important;
    margin: 0;

    .popup-header {
      background: #00aeef;
      border-bottom: 1px solid #858585;
      height: 70px;

      .popup-header-title {
        color: white;
      }
    }
  }
}

.custom .leaflet-popup-close-button {
  margin: -10px;
  color: #00aeef !important;
  background-color: white !important;
  border-radius: 50%;
  padding: 4px 1px 0 0 !important;
  width: 25px !important;
  height: 25px !important;
  border: 1px solid #dee2e6 !important;
  font: 14px/14px Tahoma, Verdana, sans-serif !important;
}