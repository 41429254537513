/* Sidebar */

.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-light-bg;
  font-family: $type-2;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;

  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;

    .nav-item {
      .collapse {
        z-index: 999;
      }

      .collapse.show,
      .collapsing {
        background: $sidebar-light-menu-active-bg;
      }

      .nav-link {
        align-items: center;
        display: flex;
        padding: $sidebar-menu-padding;
        white-space: nowrap;
        height: $nav-link-height;
        color: $sidebar-light-menu-color;

        i {
          &.menu-arrow {
            margin-left: auto;
            margin-right: 0;
            transition-duration: 0.2s;
            transition-property: transform;
            transition-timing-function: ease-in;

            &:before {
              content: "\F142";
              font-family: "Material Design Icons";
              font-size: 18px;
              line-height: 1;
              font-style: normal;
              vertical-align: middle;
              color: rgba($sidebar-light-menu-color, 0.5);
            }
          }
        }

        &[aria-expanded="true"] {
          background: $sidebar-light-menu-active-bg;

          i {
            &.menu-arrow {
              transform: rotate(90deg);
            }
          }
        }

        .menu-icon {
          margin-right: 1.25rem;
          width: $sidebar-icon-size;
          line-height: 1;
          font-size: 18px;
          color: lighten($sidebar-light-menu-icon-color, 30%);

          .rtl & {
            margin-right: 0;
            margin-left: 1.25rem;
          }
        }

        .menu-title {
          color: inherit;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
        }

        .badge {
          margin-left: auto;
        }

        &:hover {
          color: darken($sidebar-light-menu-color, 5%);
        }
      }

      &.active {
        >.nav-link {
          color: $sidebar-light-menu-active-color;

          .menu-title,
          i {
            color: inherit;
          }
        }
      }

      &.nav-profile {
        .nav-link {
          display: flex;
          flex-direction: column;
          height: auto;

          .user-wrapper {
            display: flex;
            margin-bottom: 30px;

            .profile-image {
              width: 40px;
              height: 40px;

              img {
                border-radius: 100%;
                max-width: 100%;
              }
            }

            .text-wrapper {
              margin-left: 15px;

              .rtl & {
                margin-left: 0;
                margin-right: 15px;
              }

              .profile-name {
                font-weight: 500;
                margin-bottom: 8px;
              }

              .designation {
                margin-right: 3px;
              }
            }
          }
        }
      }
    }

    &:not(.sub-menu) {
      >.nav-item {
        &:hover {
          &:not(.nav-profile) {
            >.nav-link {
              background: $sidebar-light-menu-hover-bg;
              color: $sidebar-light-menu-hover-color;
            }
          }
        }
      }
    }

    &.sub-menu {
      margin-bottom: 0;
      padding: $sidebar-submenu-padding;

      .nav-item {
        .nav-link {
          color: $sidebar-light-submenu-color;
          padding: $sidebar-submenu-item-padding;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;

          &.active {
            color: $sidebar-light-menu-active-color;
            background: transparent;

            &:before {
              background: $sidebar-light-menu-active-color;
            }
          }
        }

        &:hover {
          >.nav-link {
            background: $sidebar-light-submenu-hover-bg;
            color: $sidebar-light-submenu-hover-color;

            &:before {
              background: $sidebar-light-submenu-hover-color;
            }
          }
        }
      }
    }
  }
}

//sidebar color variation
.sidebar-dark {
  .sidebar {
    background: $sidebar-dark-bg;

    .nav {
      .nav-item {

        .collapse.show,
        .collapsing {
          background: $sidebar-dark-menu-active-bg;
        }

        .nav-link {
          color: $sidebar-dark-menu-color;

          &[aria-expanded="true"] {
            background: $sidebar-dark-menu-active-bg;
          }

          i:not(.btn i) {
            color: $sidebar-dark-menu-icon-color;

            &.menu-arrow {
              &:before {
                color: rgba($sidebar-dark-menu-color, 0.5);
              }
            }
          }

          &:hover {
            color: darken($sidebar-dark-menu-color, 5%);
          }
        }

        &.nav-profile {
          .profile-name {
            .name {
              color: $sidebar-dark-profile-name-color;
            }

            .designation {
              color: $sidebar-dark-profile-title-color;
            }
          }

          .notification-panel {
            &:before {
              background: $sidebar-dark-profile-name-color;
            }

            >span {
              background: $sidebar-dark-menu-active-bg;

              i {
                color: color(gray-light);
              }
            }
          }
        }

        &.active {
          >.nav-link {
            color: $sidebar-dark-menu-active-color;
          }
        }

        .sidebar-sticker {
          background: $sidebar-dark-menu-active-bg;
        }
      }

      &:not(.sub-menu) {
        >.nav-item {
          &:hover {
            &:not(.nav-profile) {
              >.nav-link {
                background: $sidebar-dark-menu-hover-bg;
                color: $sidebar-dark-menu-hover-color;
              }
            }
          }
        }
      }

      &.sub-menu {
        .nav-item {
          .nav-link {
            color: $sidebar-dark-submenu-color;

            &.active {
              color: $sidebar-dark-menu-active-color;

              &:before {
                background: $sidebar-dark-menu-active-color;
              }
            }
          }

          &:hover {
            >.nav-link {
              background: $sidebar-dark-submenu-hover-bg;
              color: $sidebar-dark-submenu-hover-color;

              &:before {
                background: $sidebar-dark-submenu-hover-color;
              }
            }
          }
        }
      }
    }
  }
}

/* style for off-canvas menu*/

@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    left: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;

    &.active {
      left: 0;
    }
  }
}

// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-icon;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: flex;
        }
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      .nav {
        overflow: visible;
        .nav-item {
          position: relative;
          .nav-link {
            display: block;
            text-align: center;
            padding-left: 25px;
            padding-right: 25px;
            .badge,
            .menu-title {
              display: none;
            }
            i {
              &.menu-icon {
                margin-right: 0;
              }
              &.menu-arrow {
                display: none;
              }
            }
          }
          &.nav-profile,
          .sidebar-sticker {
            display: none;
          }
          .collapse {
            display: none;
          }
          &.hover-open {
            .nav-link {
              .menu-title {
                display: flex;
                align-items: center;
                background: $sidebar-light-menu-hover-bg;
                color: $sidebar-light-menu-color;
                @at-root #{selector-append(".sidebar-dark", &)} {
                  background: $sidebar-dark-menu-hover-bg;
                  color: $sidebar-dark-menu-color;
                }
                padding: 0.5rem 1.25rem;
                left: $sidebar-width-icon;
                position: absolute;
                text-align: left;
                top: 0;
                width: $sidebar-icon-only-submenu-width;
                z-index: 1;
                height: $nav-link-height;
                line-height: 1.8;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: $sidebar-width-icon;
                  text-align: right;
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                }
                &:after {
                  display: none;
                }
              }
              &[data-toggle=collapse] {
                .menu-title {
                  border-bottom-right-radius: 0;
                  @at-root #{selector-append(".rtl", &)} {
                    border-bottom-left-radius: 0;
                  }
                }
              }
            }
            .collapse,
            .collapsing {
              display: block;
              padding: 0.5rem 0;
              background: $sidebar-light-menu-hover-bg;
              color: $sidebar-light-menu-color;
              @at-root #{selector-append(".sidebar-dark", &)} {
                background: $sidebar-dark-menu-hover-bg;
                color: $sidebar-dark-menu-color;
              }
              position: absolute;
              top: $nav-link-height;
              left: $sidebar-width-icon;
              width: $sidebar-icon-only-submenu-width;
              border-bottom-right-radius: 5px;
              @at-root #{selector-append(".rtl", &)} {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 5px;
              }
              @at-root #{selector-append(".rtl", &)} {
                left: auto;
                right: $sidebar-width-icon;
              }
              -webkit-box-shadow: 0 1px 15px 1px rgba(113, 106, 202, .08);
              -moz-box-shadow: 0 1px 15px 1px rgba(113, 106, 202, .08);
              box-shadow: 0 1px 15px 1px rgba(113, 106, 202, .08);
            }
          }
        }
        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;
          .nav-item {
            .nav-link {
              text-align: left;
              color: $sidebar-light-menu-color;
              &:hover {
                color: darken($white, 20%);
              }
              @at-root #{selector-append(".sidebar-dark", &)} {
                color: $sidebar-dark-menu-color;
              }
              @at-root #{selector-append(".rtl", &)} {
                text-align: right;
              }
            }
            &:nth-child(5n+1),
            &:nth-child(5n+2),
            &:nth-child(5n+3),
            &:nth-child(5n+4),
            &:nth-child(5n+5) {
              .nav-link {
                &:before {
                  background: $white;
                }
              }
            }
          }
        }
      }
    }
    .main-panel {
      width: calc(100% - #{$sidebar-width-icon});
    }
  }
}