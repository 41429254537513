

/*@media print {

  @page { 
    margin: 0;
    size: A4 landscape;
  }

  body {
     max-height: 99%; 
  }

}
*/



.lamp-headerTitle {

  padding-bottom: 75px;
  padding-top:50px;


}

.lamp-demographicContent {

  overflow-y:auto;
  overflow-x:hidden;
  padding: 10px;
  height:100%;
  padding-bottom:40px;
  background-color:white;
  border-radius:5px;

}

.tooltipPoiImg:hover .tooltipPoiHover {
  visibility: visible;
}

.tooltipPoiImg .tooltipPoiHover {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.poi-storageList {
  height: auto;
  max-height: 105px;
  min-height: 105px;
  width: 100%;
  padding: 1%;
  overflow-y:auto;
}

.lamp-box {
  background: #00aeef;
  border-radius: 0.25rem;
  padding: 5px;
}
