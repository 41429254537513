/* Settings Panel */

.settings-panel {
  display: block;
  position: fixed;
  top: 0;
  right: -300px;
  bottom: 0;
  width: 300px;
  height: 100vh;
  min-height: 100%;
  background: #fff;
  padding-top: 50px;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: right, -webkit-box-shadow;
  transition-property: right, -webkit-box-shadow;
  transition-property: right, box-shadow;
  transition-property: right, box-shadow, -webkit-box-shadow;
  z-index: 9999;

  .nav-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    margin: 0;
    padding: 0;
    background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);

    .nav-item {
      border: none;

      .nav-link {
        text-align: center;
        border: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.5);
        -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        &.active {
          background: transparent;
          color: #ffffff;
        }
      }
    }
  }
  .tab-content {
    border: none;
    padding: 20px 0 0;

    .tab-pane.scroll-wrapper {
      position: relative;
      max-height: 100vh;
      height: 100%;
      padding-bottom: 150px;
    }
  }

  .settings-heading {
    padding: 16px 0 13px 20px;
  }
}

.rtl .settings-panel {
  .settings-heading {
    padding: 16px 35px 13px 0;
    text-align: right;
  }

  small.settings-heading {
    padding: 16px 0 13px 12px;
  }
}

.settings-panel {
  .sidebar-bg-options {
    padding: 13px 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1;
    color: #595959;
    background: #ffffff;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -webkit-transition-property: background;
    transition-property: background;

    &.selected {
      background: #f7f7f9;
    }
  }

  .color-tiles {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 35px 10px;
    padding-top: 15px;

    .tiles {
      margin: 10px 18px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      &:before {
        content: "";
        width: 0;
        height: 0;
        opacity: 0;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        border: 0 solid rgba(255, 255, 255, 0.4);
        -webkit-transition-duration: 0.25s;
        transition-duration: 0.25s;
        -webkit-transition-timing-function: ease;
        transition-timing-function: ease;
      }

      &.selected:before {
        width: 10px;
        height: 10px;
        opacity: 1;
        border-width: 1px;
      }

      &.pink {
        background: #E91E63;
      }

      &.default {
        border: 1px solid white;
        background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
      }
    }
  }
  .chat-list {
    padding-left: 0;

    .list {
      padding: 0.4rem 0.8rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      border-bottom: 1px solid #dee2e6;

      &:last-child {
        border-bottom: none;
      }

      .profile {
        position: relative;
        margin-right: 1rem;

        img {
          width: 2.50rem;
          height: 2.50rem;
          border-radius: 100%;
        }

        span {
          height: 0.75rem;
          width: 0.75rem;
          position: absolute;
          bottom: 0.34rem;
          right: 0;
          border: 0.13rem solid #ffffff;
          border-radius: 100%;

          &.online {
            background: #19d895;
          }

          &.offline {
            background: #ffaf00;
          }
        }
      }
      .info {
        margin-right: auto;

        p {
          display: block;
          margin-bottom: 0;

          &:last-child {
            opacity: 0.5;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

.rtl .settings-panel .chat-list .list .info p:last-child {
  text-align: right;
}

.settings-panel {
  .chat-list .list.active {
    background: white;
  }

  &.open {
    right: 0;
    -webkit-box-shadow: 7px 0 80px -9px rgba(0, 0, 0, 0.2);
    box-shadow: 7px 0 80px -9px rgba(0, 0, 0, 0.2);
  }

  .settings-close {
    position: absolute;
    top: 47px;
    right: 10px;
    color: #2196f3;
    background: transparent;
    border-radius: 4px;
    padding: 0 3px;
    cursor: pointer;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    z-index: 999;

    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}

.rtl .settings-panel .settings-close {
  right: unset;
  left: 10px;
}

.settings-panel .events p {
  font-family: "roboto", sans-serif;
}

.rtl .settings-panel {
  .events p {
    text-align: right;
  }

  right: unset;
  left: -300px;
  -webkit-transition-property: left;
  transition-property: left;

  .chat-list {
    padding-right: 0;
    .list {
      .profile {
        margin-right: 0;
        margin-left: 1rem;
      }

      .info {
        margin-right: 0;
        margin-left: auto;

        .badge {
          margin-right: 10px;
        }
      }
    }
  }

  &.open {
    left: 0;
    right: unset;
  }
}

.settings-panel .demo-screen-wrapper {
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 100px;
  padding-top: 20px;

  .demo-thumb-image {
    display: block;
    margin-bottom: 5px;
    padding: 10px 25px;

    img {
      width: 100%;
      max-width: 100%;
      -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
      -webkit-transform: scale(1);
      transform: scale(1);
      border-radius: 4px;
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
      -webkit-transition-timing-function: ease;
      transition-timing-function: ease;
      -webkit-transition-property: "transform", "box-shadow", "transform", "box-shadow";
      transition-property: "transform", "box-shadow", "transform", "box-shadow";
      transition-property: "transform", "box-shadow";

      &:hover {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.5);
      }
    }
  }
}
