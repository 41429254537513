/* Sidebar Toggler */

.right-sidebar-toggler-wrapper {
  position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 99;
  padding: 5px;
  background: #F3F3F3;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);

  .sidebar-toggler {
    padding: 7px 10px;
    color: #212529;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    background: #fff;
    margin-bottom: 5px;
    border-radius: 4px;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background: rgba(33, 150, 243, 0.2);
      color: #2196f3;
    }
    &#layout-toggler {
      background: rgba(25, 216, 149, 0.2);
      color: #19d895;
    }
    a {
      color: inherit;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
