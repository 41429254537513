/*
  @type = 'bar-loader', 'circle-loader', 'dot-opacity-loader',
          'jumping-dots-loader', 'arrow-opacity-loader',
*/

/* Bar loader */
.bar-loader {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.bar-loader span {
  display: inline-block;
  width: 5px;
  height: 30px;
  margin: 0 2px;
  background-color: rgba(theme-color(primary), 1);
}

.bar-loader span:nth-child(1) {
  animation: grow 1s ease-in-out infinite;
}

.bar-loader span:nth-child(2) {
  animation: grow 1s ease-in-out 0.15s infinite;
}

.bar-loader span:nth-child(3) {
  animation: grow 1s ease-in-out 0.30s infinite;
}

.bar-loader span:nth-child(4) {
  animation: grow 1s ease-in-out 0.45s infinite;
}
@keyframes grow {
  0%,
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  50% {
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}

/* Circle loader */
.circle-loader {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.circle-loader:after,
.circle-loader:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: theme-color(primary);
}

.circle-loader:before {
  z-index: 100;
  animation: spin 1s infinite;
}

.circle-loader:after {
  border: 10px solid theme-color(secondary);
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Dot opacity loader */
.dot-opacity-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.dot-opacity-loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: theme-color(primary);
  margin: 35px 5px;
  opacity: 0;
}

.dot-opacity-loader span:nth-child(1) {
  animation: opacitychange 1s ease-in-out infinite;
}

.dot-opacity-loader span:nth-child(2) {
  animation: opacitychange 1s ease-in-out 0.33s infinite;
}

.dot-opacity-loader span:nth-child(3) {
  animation: opacitychange 1s ease-in-out 0.66s infinite;
}
@keyframes opacitychange {
  0%,
  100% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}

//-----------
/* Arrow opacity loader */
.arrow-right-loader {
  width: 50px;
  //height: 100px;
  //border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.arrow-right-loader span {
  opacity: 0;
  border: solid theme-color(danger);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);  
}

.arrow-right-loader span:nth-child(1) {
  animation: opacitychange 1.2s ease-in-out infinite;
}

.arrow-right-loader span:nth-child(2) {
  animation: opacitychange 1.2s ease-in-out 0.33s infinite;
}

.arrow-right-loader span:nth-child(3) {
  animation: opacitychange 1.2s ease-in-out 0.66s infinite;
}

/* Arrow Left */
.arrow-left-loader {
  width: 50px;
  //height: 100px;
  //border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.arrow-left-loader span {
  opacity: 0;
  border: solid theme-color(danger);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrow-left-loader span:nth-child(1) {
  animation: opacitychange 1.2s ease-in-out infinite;
}

.arrow-left-loader span:nth-child(2) {
  animation: opacitychange 1.2s ease-in-out 0.33s infinite;
}

.arrow-left-loader span:nth-child(3) {
  animation: opacitychange 1.2s ease-in-out 0.66s infinite;
}

/* Arrow Up */
.arrow-up-loader {
  width: 10px;
  height: 60px;
  //border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.arrow-up-loader span {
  opacity: 0;
  border: solid theme-color(danger);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrow-up-loader span:nth-child(3) {
  animation: opacitychange 1.2s ease-in-out infinite;
}

.arrow-up-loader span:nth-child(2) {
  animation: opacitychange 1.2s ease-in-out 0.33s infinite;
}

.arrow-up-loader span:nth-child(1) {
  animation: opacitychange 1.2s ease-in-out 0.66s infinite;
}

/* Arrow Down */
.arrow-down-loader {
  width: 10px;
  height: 60px;
  //border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.arrow-down-loader span {
  opacity: 0;
  border: solid theme-color(danger);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-down-loader span:nth-child(1) {
  animation: opacitychange 1.2s ease-in-out infinite;
}

.arrow-down-loader span:nth-child(2) {
  animation: opacitychange 1.2s ease-in-out 0.33s infinite;
}

.arrow-down-loader span:nth-child(3) {
  animation: opacitychange 1.2s ease-in-out 0.66s infinite;
}

/* ---------- directions ------------
// copy and paste to above class or create new
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
----------------------------------- */

@keyframes opacitychange {
  0%,
  100% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}

//----------

/* Jumping dots loader */
.jumping-dots-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.jumping-dots-loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: rgba(theme-color(danger), .8);
  margin: 35px 5px;
}

.jumping-dots-loader span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}

.jumping-dots-loader span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}

.jumping-dots-loader span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}
@keyframes bounce {
  0%,
  100%,
  75% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
