@import "variables";
@import "aside";
@import "misc";
@import "lamp2";

/* customize to headerless application */
/*.page-body-wrapper {
  min-height: 100vh;
  padding-top: 0px !important;
}

.main-panel {
  min-height: 100vh;
}

.main-panel .content-wrapper-fluid {
  height: 100%;
}

#navbar {
  display: none;
}
*/

.map-editable-float-div {
  display: none;
  position: absolute;
  background: #666;
  color: white;
  opacity: 0.5;
  font-size: 12px;
  padding: 5px;
  border: 1px dashed #999;
  z-index: 1000;
}

.map-editable-float-div > li:hover {
  color: blue;
  cursor: pointer;
}

.line-weight {
  border: 4px solid red;
}

.weight {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  text-align: end;
}

/* https://codepen.io/nxworld/pen/oGwxaP */
.dash1 {
  display: block;
  height: 2px;
  background-image: linear-gradient(90deg, #000, #000 75%, transparent 75%, transparent 100%);
  background-size: 20px 1px;
  border: none;
  margin: 1em 0;
  padding: 0;
}

.dash2 {
  display: block;
  height: 2px;
  background-image: linear-gradient(90deg, #000, #000 50%, transparent 50%, transparent 100%);
  background-size: 20px 1px;
  border: none;
  margin: 1em 0;
  padding: 0;
}

.dash3 {
  display: block;
  height: 2px;
  background-image: linear-gradient(90deg, #000, #000 25%, transparent 25%, transparent 100%);
  background-size: 20px 1px;
  border: none;
  margin: 1em 0;
  padding: 0;
}

.marker-image-1 {
  height: 130px;
  width: 200px;
  background: url("../../asset/marker.png") no-repeat center;
  background-size: cover;
  border: 1px solid #c3c3c3;
}

.marker-image-2 {
  height: 130px;
  width: 200px;
  background: url("../../asset/icon.png") no-repeat center;
  background-size: cover;
  border: 1px solid #c3c3c3;
}

#nav-custom-1 {
  &.nav-tabs {
    padding-top: 5px;
  }
  &.nav-tabs .nav-link {
    border: none;
    color: #c3c3c3;
    &.active {
      color: #000000;
      border: none;
      border-bottom: #00ce68 solid 3px;
    }
  } 
}

////////// UI-ASIDE //////////
// either width or display
// but display cannot be animated?
.ui-aside {
  height: 100%;
  min-width: $aside-main-width;
  width: $aside-main-width;
  background-color: #ffffff;
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  transition: all 0.15s ease-in, background 0.15s ease-in;
  -webkit-transition: all 0.15s ease-in, background 0.15s ease-in;
  -moz-transition: all 0.15s ease-in, background 0.15s ease-in;
  -ms-transition: all 0.15s ease-in, background 0.15s ease-in;

  &.hide {
    display: none !important;
  }
}

.ui-aside-lamp {
  height: 100%;
  min-width: $aside-lamp-main-width;
  width: $aside-lamp-main-width;
  background-color: #ffffff;
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  transition: all 0.15s ease-in, background 0.15s ease-in;
  -webkit-transition: all 0.15s ease-in, background 0.15s ease-in;
  -moz-transition: all 0.15s ease-in, background 0.15s ease-in;
  -ms-transition: all 0.15s ease-in, background 0.15s ease-in;

  &.hide {
    display: none !important;
  }
}

.ui-aside-second {
  height: calc(100% - 63px);
  width: 0;
  position: absolute;
  z-index: 2;
  top: $navbar-height;
  left: $aside-main-width + $sidebar-width-icon;
  background-color: #e9ecef;
  overflow-x: hidden;
  transition: width 0.15s ease-in, background 0.15s ease-in;
  -webkit-transition: width 0.15s ease-in, background 0.15s ease-in;
  -moz-transition: width 0.15s ease-in, background 0.15s ease-in;
  -ms-transition: width 0.15s ease-in, background 0.15s ease-in;

  &.show {
    width: $aside-second-width;
  }
}

@media screen and (max-width: 991px) {
  .ui-aside-second {
    width: 0;
    left: $aside-main-width;
  }
}
////////// UI-ASIDE //////////
////////// UI-ASIDE-2 //////////
.ui-aside-2 {
  height: 100%;
  width: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  //border-left: 1px solid #d4d4d4;
  //border-right: 1px solid #d4d4d4;
  overflow: auto;
  //transition: opacity 0.15s ease-in;
  //-webkit-transition: opacity 600ms, visibility 600ms;
  //transition: opacity 600ms, visibility 600ms;
  transition: width 0.15s ease-in, background 0.15s ease-in;
  -webkit-transition: width 0.15s ease-in, background 0.15s ease-in;
  -moz-transition: width 0.15s ease-in, background 0.15s ease-in;
  -ms-transition: width 0.15s ease-in, background 0.15s ease-in;

  &.show {
    width: 330px;
    border-right: 1px solid #d4d4d4;
  }
}
////////// UI-ASIDE-2 //////////
////////// ACCORDION //////////
.card-accordion {
    box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11), 0 4px 16px 0 rgba(167, 175, 183, 0.33);;
    border: solid 1px #dde4eb;
    border-radius: 0px;

    .card-title {
        color: #001737;
    }

    .card-header {
        background: #fff;
    }
}
////////// ACCORDION //////////
////////// UI FLOAT //////////
.lamp-analytic {
  position: absolute;
  display: flex;
  flex-direction: row;

  transition: width 0.15s ease-in, background 0.15s ease-in;
  -webkit-transition: width 0.15s ease-in, background 0.15s ease-in;
  -moz-transition: width 0.15s ease-in, background 0.15s ease-in;
  -ms-transition: width 0.15s ease-in, background 0.15s ease-in;

  .lamp-analytic-card {
    height: calc(100vh - 85px);
    width: 400px;
    position: absolute;
    margin: 10px;
    margin-left: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #c3c3c3;

    &:nth-child(2) {
      left: 410px;
    }

    &:nth-child(3) {
      left: 820px;
    }
  }  
}

@media screen and (max-width: 991px) {
  .lamp-analytic {
    display: flex;
    flex-direction: column;
  }
}

.ui-float-right {
  height: 100%;
  width: 300px;
  //background-color: #e9ecef;
  z-index: 1;
  //bottom: 10px;
  //top: 10px;
  right: 0;
  display: flex;
  flex-direction: column;
  position: absolute;

  .child {
    background-color: #ffffff;
  }
}
////////// UI FLOAT //////////
.list-job {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: $list-width;

  .list-job-body {
    height: 100vh;
    max-width: $list-width;
    //overflow-y: auto;
    position: fixed;
  }
}
////////////////////////////////
////////// BOTTOM-BAR //////////
.bottom-bar {
  &.container {
    height: 30px;
    position: fixed;
    bottom: 0;
    width: 300px;
    background-color: #dde4eb;
    z-index: 1;
    padding: 0;
  }

  .header {
    cursor: pointer;
    width: 300px;
    height: 30px;
    background-color: #dde4eb;
  }

  .body {
    background-color: #f2f2f2;
    overflow-y: auto;
    height: 100%;
    max-height: 250px;
  }
}
////////////////////////////////
////////// JOURNEY-PLANNER //////////
#plannerMenu {
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;

  &.hide {
    width: 0 !important;
    opacity: 0 !important;
  }
}

#routeInfo {
  position: relative;
  right: 0;
  width: 0;
  height: 100%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;

  &.show {
    width: 100% !important;
    opacity: 1 !important;
  }
}
////////////////////////////////
////////// badge indicator //////////
.badge-indicator {
  margin-top: -3px;
  margin-left: -13px;
  margin-right: 5px;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background: #FF0017;
  color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  line-height: 1rem;
  border: none;
  text-align: center;
}

.customShadow {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
}
////////////////////////////////
////////// PRASARANA-CUSTOM //////////
.pageContainer {
  display: flex !important;
  height: 100% !important;
}
.ui-aside { width: 30%; }
.map-container {
  display: flex !important;
  flex-direction: column !important;
  flex-grow: 1 !important;
}
#plannerMenu {
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
#plannerMenu input[type=time] { text-align: center !important; }
.route_summary { justify-content: center; }

#fareCalculator.pageContainer {
  display: flex !important;
  flex-direction: column !important;
}

#calculatorMenu .btn.btn-outline-secondary {
  border: 1px solid #c3c3c3 !important;
}

#stations_search input.form-control[type=text]:not(:focus) {
  border: 1px solid #c3c3c3 !important;
}
#stations_search input.form-control[type=text]::placeholder {
  color: inherit !important;
}

.mobileView {
  flex-direction: column-reverse !important;

  .ui-aside {
    width: 100% !important;
    &.show-route { height: 50% !important }
  }
  .map-container.show { height: 50% !important }
  .route_summary { justify-content: flex-start !important; }
  #routeInfo {
    height: 50%;
    position: absolute;
    bottom: 0;
  }

  .nav a {
    width: 100% !important;
  }
}

.pra-title {
  color: #ffffff;
  background: #0032A0;
}

.pra-btn-grp {
  color: #0032A0;

  .btn-primary {
    background-color: #0032A0;
  }
}