// pretty scrollbar for webkit browser
::-webkit-scrollbar{position:absolute;-webkit-appearance:none;width:10px}
::-webkit-scrollbar-thumb{background-clip:padding-box!important;border-radius:5px;color:transparent}
::-webkit-scrollbar-track{background:#eee}
::-webkit-scrollbar-thumb{background:#d9d9de;box-shadow:inset 0 -2px,inset 0 -3px,inset 0 2px,inset 0 3px;min-height:36px}
::-webkit-scrollbar-corner{background:#fff}

.main-panel {
  max-height: calc(100vh - #{$navbar-height});
  .content-wrapper-fluid {
    height: calc(100% - 60px);
    #content {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

input.form-control { height: auto; }
.dropdown-item, .list-group-item { cursor: pointer; }
.footer { height: 60px; }

.btn-group {
  .btn { margin-right: 0px !important; }
}

.nav-row {
  overflow: hidden;
  .tab-content {
    padding: 10px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .btn { margin-right: 0px !important; }
  }
}

.progress {
  overflow: hidden;
  border-radius: 0.25rem;
  background-color: #e9ecef;
  height: 25px;
  position: relative;
  small { position: absolute; }
}

.badge {
  font-weight: 400;
}

.hoverBtn {
  position: absolute;
  width: 500px;
  left:0;
  top: 180px;
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
}

/* material design icon */
.alert.mdi::before,
.breadcrumb .mdi::before,
.btn.mdi::before,
.card-title.mdi::before,
.card-subtitle.mdi::before,
.card-link.mdi::before,
.dropdown-item.mdi::before,
.list-group-item.mdi::before,
.nav-link.mdi::before {
    font-size: 1.25em;
    line-height: initial;
    position: relative;
    top: 0.09rem;
}
.alert.mdi::before,
.breadcrumb .mdi:not(:empty)::before,
.btn.mdi:not(:empty)::before,
.card-title.mdi:not(:empty)::before,
.card-subtitle.mdi:not(:empty)::before,
.card-link.mdi:not(:empty)::before,
.dropdown-item.mdi:not(:empty)::before,
.nav-link.mdi:not(:empty)::before {
    margin-right: 0.25rem;
}
.list-group-item.mdi:not(:empty)::before {
    margin-right: 0.5rem;
}
.dropdown-item.mdi:not(:empty)::before {
    margin-left: -0.75rem;
}
.alert.mdi::before,
.list-group-item.mdi:not(:empty)::before {
    margin-left: -0.5rem;
}
.modal-title.mdi::before {
    font-size: 1.5em;
    line-height: 0.5;
    position: relative;
    top: 0.26rem;
    margin-right: 0.5rem;
}

/* fix pixel width class */
@for $i from 5 through 40 { // create width 50px -> 400px
  .w-px-#{$i * 10} {
    width: #{$i * 10}px;
  }
}

// https://stackoverflow.com/questions/34833815/showing-truncated-text-on-hover-using-css-ellipsis-overlaps-text-below-it
.box{
  background:#fff;
  box-shadow: 0 25px 30px 0 rgba(0,0,0,0.15);
  border:rgba(0,0,0,0.3);
  width:10rem;
  margin:2rem auto;
  padding:2rem;
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis:focus, .ellipsis:hover {
  color:transparent;
}
.ellipsis:focus:after,.ellipsis:hover:after{
  content:attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left:auto;
  top:auto;
  width: auto;
  max-width: 20rem;
  border: 1px solid #eaebec;
  padding: 0 .5rem;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.28);
  white-space: normal;
  word-wrap: break-word;
  display:block;
  color:black;
  margin-top:-1.25rem;
}