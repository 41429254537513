@import "variables";

.ui-aside-share {
  height: 100%;
  min-width: $aside-main-share-width;
  width: $aside-main-share-width;
  background-color: #ffffff;
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  transition: all 0.15s ease-in, background 0.15s ease-in;
  -webkit-transition: all 0.15s ease-in, background 0.15s ease-in;
  -moz-transition: all 0.15s ease-in, background 0.15s ease-in;
  -ms-transition: all 0.15s ease-in, background 0.15s ease-in;

  &.hide {
    display: none !important;
  }
}