/* Dropdowns */

.dropdown-menu {
  font-size: $default-font-size;

  .dropdown-item {
    &:active {
      background: initial;
    }
  }
}
